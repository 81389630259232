import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useInterval } from "@src/appV2/lib/utils/useInterval";
import { useCallback, useRef } from "react";

/**
 * Hook that periodically checks for white screen conditions and logs events when detected
 */
export function useWhiteScreenDetection() {
  const lastDetectionRef = useRef<number | undefined>(undefined);
  const {
    isEnabled: isWhiteScreenDetectionEnabled,
    checkIntervalMs,
    logIntervalMs,
    maximumNumberOfElementsForWhiteScreen,
  } = useCbhFlag(CbhFeatureFlag.WHITE_SCREEN_DETECTION, {
    defaultValue: {
      isEnabled: false,
      logIntervalMs: 60_000, // The amount of time between logging events
      checkIntervalMs: 5_000, // The amount of time between checking for white screen
      maximumNumberOfElementsForWhiteScreen: 3, // The maximum number of elements that should be visible to be considered a white screen
    },
  });

  // Function to detect white screens by checking DOM content
  const checkForWhiteScreen = useCallback(() => {
    if (!isWhiteScreenDetectionEnabled) {
      return;
    }

    try {
      // Get visible elements in viewport that aren't just structural/invisible
      const visibleElements = Array.from(
        document.querySelectorAll(
          "#root *:not(script):not(style):not(meta):not(link):not([aria-hidden='true'])"
        )
      ).filter(
        (node) =>
          isDefined(node.textContent) &&
          node.textContent.trim() !== "" && // Uses `textContent` for broader compatibility
          node.children.length === 0 // Ensures it's a leaf node (faster than `!node.querySelector("*")`)
      );

      // Check if the main content area has less than the maximum number of elements for white screen
      const isWhiteScreen = visibleElements.length <= maximumNumberOfElementsForWhiteScreen;

      if (isWhiteScreen) {
        // Prevent logging too many events in a short period
        const now = Date.now();
        const shouldLog =
          !lastDetectionRef.current || now - lastDetectionRef.current > logIntervalMs;

        if (shouldLog) {
          lastDetectionRef.current = now;

          logEvent(APP_V2_APP_EVENTS.WHITE_SCREEN_DETECTED, {
            url: window.location.href,
            maximumNumberOfElementsForWhiteScreen,
            visibleElementCount: visibleElements.length,
          });
        }
      }
    } catch (error) {
      logEvent(APP_V2_APP_EVENTS.WHITE_SCREEN_DETECTION_ERROR, {
        error: error instanceof Error ? error.message : String(error),
      });
    }
  }, [isWhiteScreenDetectionEnabled, logIntervalMs, maximumNumberOfElementsForWhiteScreen]);

  useInterval(checkForWhiteScreen, checkIntervalMs);
}
